<template>
  <div>
    <!-- input search -->
    <div class="d-flex ">
      <b-col
        md="3"
      >
        <b-form-group
          label="Année universitaire"
        >
          <v-select
            v-model="academicYear"
            :clearable="false"
            label="academic_year"
            placeholder="Année universitaire"
            :options="academicYears"
          />

        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Parcours"
        >
          <v-select
            v-model="parcour"
            :clearable="false"
            placeholder="parcour"
            label="title"
            :options="parcours"
            @input="getSubjects"
          />

        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Niveau"
        >
          <v-select
            v-model="level"
            :clearable="false"
            placeholder="Niveau"
            :options="levels"
            @input="getSubjects"
          />

        </b-form-group>
      </b-col>

      <!-- <b-col md="3">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </b-col> -->
      <!-- <b-col
        md="3"
        class="mt-2"
      >
        <b-form-group>
          <b-button
            :to="{ name: 'subject-add' }"
            variant="gradient-primary"
            class="ml-1 d-inline-block"
          >
            <feather-icon icon="PlusIcon" />
            <span class="ml-1">Ajouter Matière</span>
          </b-button>
        </b-form-group>
      </b-col> -->
    </div>
    <div
      v-if="load === 'true'"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <h4
      style="color:#0020d1;"
      class="mt-2"
    >
      Semestere 1 :
    </h4>
    <vue-good-table
      :columns="columns"
      :rows="semester1"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'username'"
          class="text-nowrap"
        >
          <b-avatar
            src="/assets/images/avatars/user.png"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.username }}</span>
        </div>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <router-link
              class="text-warning"
              :to="{ name: 'subject-edit',
                     params: { id: props.row.id} }"
            >    <feather-icon
              :id="`invoice-row-${props.row.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>

            <b-tooltip
              title="Modifier"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />

            <feather-icon
              :id="`invoice-row-${props.row.id}-trash-icon`"
              icon="TrashIcon"
              class="text-danger cursor-pointer"
              size="16"
              @click="deleteSubjects(props.row.id)"
            />
            <b-tooltip
              title="Supprimer"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-trash-icon`"
            />

          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <h4
      style="color:#0020d1;"
      class="mt-2"
    >
      Semestere 2 :
    </h4>
    <vue-good-table
      :columns="columns"
      :rows="semester2"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'username'"
          class="text-nowrap"
        >
          <b-avatar
            src="/assets/images/avatars/user.png"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.username }}</span>
        </div>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <router-link
              class="text-warning"
              :to="{ name: 'teaching-units-edit',
                     params: { id: props.row.id} }"
            >    <feather-icon
              :id="`invoice-row-${props.row.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>

            <b-tooltip
              title="Modifier"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />

            <feather-icon
              :id="`invoice-row-${props.row.id}-trash-icon`"
              icon="TrashIcon"
              class="text-danger cursor-pointer"
              size="16"
              @click="deleteSubjects(props.row.id)"
            />
            <b-tooltip
              title="Supprimer"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-trash-icon`"
            />

          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->

    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormSelect,
  BSpinner, BTooltip, BCol,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeAuth from '@/store/store'
import vSelect from 'vue-select'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    // BFormInput,
    BFormSelect,
    // BButton,
    BSpinner,
    BTooltip,
    vSelect,
    BCol,
  },
  data() {
    return {
      load: 'false',
      pageLength: 10,
      user: {},
      academicYear: {},
      academicYears: [],
      unity__semester: '',
      level: '',
      semesters: ['semester 1', 'semester 2'],
      levels: [1, 2, 3, 4, 5, 6, 7, 8],
      columns: [
        {
          label: 'UnitéEns',
          field: 'unity.label',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Type',
          field: 'unity.type',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher ',
          },
        },
        {
          label: 'Matière',
          field: 'label',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher ',
          },
        },
        {
          label: 'ModeEx',
          field: 'exam_mode',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher ',
          },
        },
        {
          label: 'Coef',
          field: 'coefficient',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher ',
          },
        },
        {
          label: 'Crédit',
          field: 'credit',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'ASS',
          field: 'assiduity',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'DS',
          field: 'ds',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher ',
          },
        },
        {
          label: 'Type DS',
          field: 'supervised_ds_mode',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher ',
          },
        },
        {
          label: 'TP',
          field: 'tp',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher ',
          },
        },
        {
          label: 'MP',
          field: 'mini_project',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher ',
          },
        },
        {
          label: 'Ex',
          field: 'exam',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher ',
          },
        },
        {
          label: 'Type Ex',
          field: 'supervised_exam_mode',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Volume H',
          field: 'hourly_volume',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher ',
          },
        },

        {
          label: 'Active',
          field: 'is_active',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'Date création',
          field: 'created_at',
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      semester1: [],
      semester2: [],
      parcours: [],
      parcour: {},
      searchTerm: '',
      currentAcademicYear: {},

    }
  },
  computed: {
    active() {
      const statusColor = {
        true: 'light-success',
        false: 'light-danger',
      }

      return status => statusColor[status]
    },

  },
  created() {
    this.getParcours()
    this.getAcademicYears()
    this.getSubjects()
    this.user = storeAuth.state.user
    this.currentAcademicYear = storeAuth.state.currentAcademicYear
    this.academicYear = storeAuth.state.currentAcademicYear
  },
  methods: {
    async getAcademicYears() {
      const response = await axios.get('api/academic-years/')
      this.academicYears = response.data
    },
    async getParcours() {
      const response = await axios.get('/api/parcours/')
      this.parcours = response.data
    },
    async getSubjects() {
      this.load = 'true'
      const response = await axios.get('/api/subjects/', {
        params: {
          academic_year_id: this.currentAcademicYear.id, semester: this.semester, parcour_id: this.parcour.id, level: this.level,
        },
      })
      const result = response.data
      this.semester1 = result.filter(x => x.semester === 'semester 1')
      this.semester2 = result.filter(x => x.semester === 'semester 2')
      // this.semaines = response.data
      this.load = 'false'
    },

    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(
        2,
        '0',
      )} | ${h}`
    },
    deleteSubjects(id) {
      this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux supprimer ! ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.load = 'true'
        if (result.value) {
          axios
            .delete(`/api/subjects/delete/${id}/`)
            .then(() => {
              this.load = 'false'
              this.getSubjects()
              setTimeout(() => {
                this.showToast('success', 'top-center', 'Matiere supprimée avec succés')
              }, 1000)
            })
            .catch(error => {
              this.load = 'false'
              setTimeout(() => {
                this.showToast('danger', 'top-center', error.toString())
              }, 1000)
            })
        } else if (result.dismiss === 'cancel') {
          this.load = 'false'
          this.$swal({
            title: 'Fermer',
            text: 'Matiere Non Supprimée :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    // toast
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
